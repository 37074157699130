body {
    // CSS 变量定义
    --primary-color: #27cb7f;
    --djs-palette-bg-color: #ffffff;
    --canvas-bg-color: #dbdbdb;
}

body {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

// 隐藏模拟流转的toggle按钮
.bts-toggle-mode {
    display: none;
}

//侧边栏配置
.djs-palette.open {
    .djs-palette-entries {

        div[class^='bpmn-icon-']:before,
        div[class*='bpmn-icon-']:before {
            line-height: unset;
        }

        div.entry {
            position: relative;
        }

        div.entry:hover {
            &::after {
                width: max-content;
                content: attr(title);
                vertical-align: text-bottom;
                position: absolute;
                right: -10px;
                top: 0;
                bottom: 0;
                overflow: hidden;
                transform: translateX(100%);
                font-size: 0.5em;
                display: inline-block;
                text-decoration: inherit;
                font-variant: normal;
                text-transform: none;
                background: #fafafa;
                box-shadow: 0 0 6px #eeeeee;
                border: 1px solid #cccccc;
                box-sizing: border-box;
                padding: 0 16px;
                border-radius: 4px;
                z-index: 100;
            }
        }
    }
}

// palette框: 背景和边框样式
.djs-palette {
    // todo 这里的背景色需要跟随明暗主题而变化
    background: var(--djs-palette-bg-color);
    border: solid 1px var(--primary-color);
    border-radius: 2px;
}

// palette框内的分割线: 颜色
.djs-palette .separator {
    border-bottom: solid 1px var(--primary-color);
}

// palette内的元素: hover效果
.djs-palette .entry:hover {
    color: var(--primary-color);
}

// palette内的元素(抓手工具、套索工具、空间工具、全局连接工具): 选中效果
.djs-palette .highlighted-entry {
    color: var(--primary-color) !important;
}

// 元素外的虚线框: 选中效果
.djs-element.selected .djs-outline {
    stroke: var(--primary-color);
    stroke-width: 1px;
}

// 元素外的虚线框: hover效果
.djs-element.hover .djs-outline {
    stroke: var(--primary-color);
    stroke-width: 1px;
}

// palette内的元素(创建分组工具): 选中效果
svg.new-parent {
    background: var(--shape-drop-allowed-fill-color) !important;
}

// 页面元素：拖取样式
.djs-dragger * {
    fill: none !important;
    stroke: var(--primary-color) !important;
}

// 对齐线：拖动元素对齐时的对齐线样式
.djs-snap-line {
    stroke: var(--primary-color);
    stroke-linecap: round;
    stroke-width: 2px;
    pointer-events: none;
}

// 选取palette元素时：准备放置时的背景
svg.new-parent {
    background: transparent !important;
}

// 元素文字: 颜色
.djs-label {
    fill: var(--primary-color) !important;
}

// canvas右下角的bpmn.io文字样式: 颜色
.bjs-container>.bjs-powered-by {
    color: var(--primary-color) !important;
}
@primary-color: #2CBBA6;