@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
// @import "~design-system/build/css/design-system.css";
@import "theme/colors.css";
@import "theme/defaultTheme.css";
@import "theme/wds.css";
@import '~antd/dist/antd.less';
@import "theme/pageplugDesign.css";

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF !important;

  .taro-img__mode-aspectfit {
    vertical-align: top;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-pro-sider.ant-layout-sider-collapsed .ant-pro-sider-logo {
    padding: 8px 8px;
  }

  .ant-pro-sider-light .ant-pro-sider-logo {
    padding: 8px 16px;
    background: var(--layout-header-bg-color);

    h1 {
      color: #fff;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .ant-pro-sider-menu .anticon {
    font-size: 19px!important;
  }

  .ant-layout.ant-layout-has-sider {
    z-index: 1;
  }

  .ant-pro-sider-light {
    z-index: 1;
  }

  .ant-pro-basicLayout .ant-layout-header.ant-pro-header-light {
    z-index: 1!important;
  }

  .rc-tree-select-tree {
    border: none;
  }
  .rc-tree-select-dropdown {
    border: none;
  }
  .rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher,
  .rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox{
    background-image: none!important;
  }
  .rc-tree-select-single:not(.rc-tree-select-customize-input) .rc-tree-select-selector {
    padding: 0;
  }
}

body.dragging *{
  cursor: grabbing !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

* {
  outline: none !important;
}

div.bp3-select-popover .bp3-popover-content {
  padding: 0;
}

span.bp3-popover-target {
  display: flex;
  width: 100%;
}

span.bp3-popover-target > * {
  flex-grow: 1;
}

div.bp3-popover-arrow {
  display: none;
}

.bp3-button.bp3-loading {
  cursor: default !important;
}

.display-none {
  display: none;
}

.Toastify__toast {
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.Toastify__toast-body {
  margin: 0 !important;
}

.Toastify__progress-bar--default {
  background: rgb(3, 179, 101) !important;
}

.t--editor-appname-menu-portal {
  z-index: 9 !important;
}

.bp3-popover .bp3-input {
  outline: 0;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3 !important;
}

.bp3-popover .bp3-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.layout-control.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.bp3-popover.none-shadow-popover {
  box-shadow: none;
  border: 0px;
}

.bp3-datepicker {
  border-radius: 0 !important;
}

/* rich text editor styles */
.tox-tinymce {
  border-color: #E7E7E7 !important;
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type), .tox .tox-statusbar {
  border-color: #E7E7E7 !important;
}

.tox .tox-toolbar__primary {
  background: white !important;
  border-bottom: 1px solid #E7E7E7 !important;
}

/* making both the Modal layer and the Dropdown layer */
.bp3-modal-widget, .appsmith_widget_0 > .bp3-portal {
  z-index: 2 !important;
}

/* Portals on the Modal widget */
.bp3-modal-widget .bp3-portal {
  z-index: 21 !important;
}

.bp3-overlay-backdrop {
  background-color: rgba(16, 22, 26, 0.7) !important;
}

.bp3-popover, .bp3-popover2, .bp3-popover2 .bp3-popover2-content, .bp3-popover .bp3-popover-content {
  border-radius: 3px !important;
}

.bp3-dialog {
  border-radius: 8px !important;
}

.bp3-overlay .bp3-overlay-content {
  border-radius: 5px !important;
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  font-family: auto!important;
}

.full-height-form {
  height: 100%;
  
  & > form {
    height: 100%;
  }
}

.designable-drawer {

  .dn-main-panel.root {
    height: auto;
    top: 55px;
    bottom: 53px;
  }
  
  .dn-light {
    --dn-aux-cover-rect-dragging-color: fade(color(lighten(@primary-color, 15%)), 30%);
    --dn-aux-cover-rect-dropping-color: fade(@primary-color, 6%);
    --dn-aux-free-selection-background-color: @primary-color;
    --dn-aux-free-selection-border-color: @primary-color;
    --dn-aux-insertion-color: @primary-color;
    --dn-aux-dashed-box-color: @primary-color;
    --dn-aux-dashed-box-title-color: #333;
    --dn-aux-selection-box-border-color: @primary-color;
    --dn-aux-selection-box-color: #333;
    --dn-ghost-color: #fff;
    --dn-ghost-bg-color: fade(color(darken(@primary-color, 15%)), 80%);
    --dn-outline-tree-bg-color: #fff;
    --dn-outline-tree-header-border-color: #eee;
    --dn-outline-tree-color: #333;
    --dn-outline-tree-insertion-bg-color: @primary-color;
    --dn-outline-tree-node-selected-color: #f3f3f3;
    --dn-outline-tree-node-header-color: #333;
    --dn-outline-tree-node-hover-color: @primary-color;

    --dn-toolbar-input-color: rgba(0, 0, 0, 0.85);
    --dn-toolbar-input-bg-color: #fff;
    --dn-toolbar-input-border-color: #d9d9d9;
    --dn-toolbar-input-hover-border-color: #d9d9d9;
    --dn-toolbar-input-handler-bg-color: #fff;

    --dn-resize-handle-bg-color: #f0f0f0;
    --dn-resize-handle-hover-bg-color: #fafafa;
    --dn-resize-handle-color: #666;
    --dn-resize-handle-hover-color: #aaa;

    --dn-mobile-simulator-bg-color: #eee;
    --dn-mobile-simulator-body-bg-color: #fff;
    --dn-mobile-simulator-border-color: #222;
    --dn-responsive-simulator-bg-color: #fff;
    --dn-pc-simulator-bg-color: #fff;

    --dn-aux-selector-btn-color: #fff;
    --dn-aux-selector-btn-bg-color: @primary-color;
    --dn-aux-selector-btn-border-color: @primary-color;
    --dn-aux-selector-btn-hover-color: #fff;
    --dn-aux-selector-btn-hover-bg-color: lighten(@primary-color, 10%);
    --dn-aux-selector-btn-hover-border-color: lighten(@primary-color, 10%);
    --dn-aux-selector-btn-active-color: #fff;
    --dn-aux-selector-btn-active-bg-color: darken(@primary-color, 10%);
    --dn-aux-selector-btn-active-border-color: darken(@primary-color, 10%);

    --dn-composite-panel-tabs-bg-color: #fff;
    --dn-composite-panel-tabs-active-bg-color: #fff;
    --dn-composite-panel-highlight-bg-color: #f0f0f0;
    --dn-composite-panel-tabs-color: #666;
    --dn-composite-panel-tabs-hover-color: @primary-color;
    --dn-composite-panel-tabs-content-border-color: #eee;
    --dn-composite-panel-tabs-content-bg-color: #fff;
    --dn-composite-panel-tabs-header-color: #666;

    --dn-drag-source-header-border-color: #eee;
    --dn-drag-source-header-color: #888;
    --dn-drag-source-header-hover-color: #333;
    --dn-drag-source-item-border-color: #aaa;
    --dn-drag-source-item-color: #333;
    --dn-drag-source-item-hover-border-color: lighten(@primary-color, 10%);
    --dn-drag-source-item-hover-color: lighten(@primary-color, 10%);

    --dn-main-panel-header-bg-color: #fff;
    --dn-main-panel-header-border-color: #eee;
    --dn-workspace-panel-bg-color: #eee;

    --dn-scrollbar-color: rgba(0, 0, 0, 0.2);
    --dn-scrollbar-hover-color: rgba(0, 0, 0, 0.3);

    --dn-btn-color: rgba(0, 0, 0, 0.85);
    --dn-btn-border-color: #d9d9d9;
    --dn-btn-bg-color: #fff;

    --dn-btn-hover-color: lighten(@primary-color, 10%);
    --dn-btn-hover-border-color: lighten(@primary-color, 10%);
    --dn-btn-hover-bg-color: #fff;

    --dn-btn-active-color: darken(@primary-color, 10%);
    --dn-btn-active-bg-color: #fff;
    --dn-btn-active-border-color: darken(@primary-color, 10%);

    --dn-btn-disabled-color: rgba(0, 0, 0, 0.25);
    --dn-btn-disabled-bg-color: #f5f5f5;
    --dn-btn-disabled-border-color: #d9d9d9;

    --dn-btn-primary-color: #fff;
    --dn-btn-primary-bg-color: @primary-color;
    --dn-btn-primary-border-color: @primary-color;

    --dn-btn-primary-hover-color: #fff;
    --dn-btn-primary-hover-bg-color: lighten(@primary-color, 10%);
    --dn-btn-primary-hover-border-color: lighten(@primary-color, 10%);

    --dn-btn-primary-active-color: #fff;
    --dn-btn-primary-active-bg-color: darken(@primary-color, 10%);
    --dn-btn-primary-active-border-color: darken(@primary-color, 10%);

    --dn-btn-primary-disabled-color: rgba(0, 0, 0, 0.25);
    --dn-btn-primary-disabled-bg-color: #f5f5f5;
    --dn-btn-primary-disabled-border-color: #d9d9d9;

    --dn-btn-text-shadow-color: rgba(0, 0, 0, 0.12);
    --dn-btn-box-shadow-color: rgba(0, 0, 0, 0.045);
    --dn-empty-bg-color: #fff;
  }
}

/* react-sortable-tree styles */
.pageplug-rst {
  .rstcustom__expandButton {
    background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+") no-repeat center;
  }
  .rstcustom__collapseButton {
    background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==") no-repeat center;
  }
  .rstcustom__rowContents {
    box-shadow: none;
    cursor: grab;
    border: 1px solid var(--ads-color-brand);
    border-radius: 4px;
    background: var(--ads-color-background-secondary);
  }
  .rstcustom__rowWrapper {
    padding: 10px 10px 10px 0;
    .rstcustom__rowLandingPad::before {
      border: 3px dashed var(--ads-color-brand);
      background: var(--ads-color-background-secondary);
      border-radius: 4px;
    }
    .rstcustom__rowCancelPad::before {
      border: 3px dashed @error-color;
      background: @red-2;
    }
  }
  .rst__placeholder::before {
    z-index: 0;
  }
  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after,
  .rstcustom__lineChildren::after {
    background-color: #c7c7c7;
  }
  .rstcustom__rowWrapper:hover {
    opacity: 1;
    .rstcustom__rowContents {
      background: #fff;
      box-shadow: 0 0 0 4px var(--ads-color-background-secondary);
    }
  }
  .icon-select-container {
    display: inline-block;
  }
  .rst__placeholder {
    max-width: unset;
    &::before {
      left: 20px;
      right: 20px;
    }
    &.rst__placeholderLandingPad::before {
      border: 3px dashed var(--ads-color-brand);
      background: var(--ads-color-background-secondary);
      border-radius: 4px;
    }
    &.rst__placeholderCancelPad::before {
      border: 3px dashed @error-color;
      background: @red-2;
    }
  }
  .rstcustom__toolbarButton {
    margin-right: 5px;
  }
}

::selection {
  color: var(--ads-color-brand-text);
  background: var(--ads-color-brand);
}

@primary-color: #2CBBA6;