.title {
    text-align: center;
    position: relative;
    width: 100%;
    font-size: 16px;
    color: #99A0A3;
    /* top: -20px; */
}

.title:after,
.title:before {
    content: "";
    position: absolute;
    top: 50%;
    height: 0px;
    width: 30%;
    border: 1px dashed #979797;
}

.title:after {
    left: 0;
}

.title:before {
    right: 0;
}