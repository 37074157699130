.box {
    background-image: url(../../assets//images/login-background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cont-box {
    background-color: rgba(255, 255, 255, .9);
}